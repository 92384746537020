import React from "react";
import video1 from "../videos/video1.mp4";
import video3 from "../videos/video3.mp4";
import video4 from "../videos/video4.mp4";
import video5 from "../videos/video5.mp4";
import video6 from "../videos/video6.mp4";
import video7 from "../videos/video7.mp4";


export default function Videos() {
  return (
    <div className=" flex  gap-10 justify-center items-center flex-wrap ">
      <video width="500" height="360" controls>
        <source src={video3} type="video/mp4" />
      </video>
      <video width="500" height="360" controls>
        <source src={video1} type="video/mp4" />
      </video>
      

      <video width="500" height="360" controls>
        <source src={video4} type="video/mp4" />
      </video>
      <video width="500" height="360" controls>
        <source src={video5} type="video/mp4" />
      </video>
      <video width="500" height="360" controls>
        <source src={video6} type="video/mp4" />
      </video>

      <video width="500" height="360" controls>
        <source src={video7} type="video/mp4" />
      </video>
    </div>
  );
}
