import React from "react";
import gal5 from "../assets/gal5.jpg";
import founder from "../assets/gajendra.jpg"
import offcer from "../assets/chand.jpg"

export default function Teams() {
  return (
    <div className=" md:px-0 px-4 flex flex-col gap-10 justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-6">
        <span className=" md:text-4xl text-3xl text-gray-700 font-semibold tracking-widest">
          {" "}
          Meet Our Consultants
        </span>
        <span className=" md:px-80 md:text-xl text-gray-500 ">
        Meet Our Consultants at Griks Global Education Consultancy, a team of experienced professionals dedicated to providing you a personalized guidance and support for your abroad study.
        </span>
      </div>
      <div className="  grid md:grid-cols-3 grid-cols-1 gap-10 items-center md:w-10/12">
        {members.map((member, index) => (
          <div
            key={index}
            className="   group flex flex-col gap-1  border rounded-2xl  bg-primary-500 px-6 py-5  hover:bg-primary-600 duration-500"
          >
            <img
              className=" h-96 w-96 group-hover:scale-105  duration-700 cursor-pointer object-cover rounded-2xl"
              src={member.img}
              alt=""
            />
            <span className="  italic group-hover:underline duration-700 mt-4 text-gray-300 text-xl font-semibold">
              {member.name}
            </span>
            <span className=" text-xl text-gray-400 font-semibold ">
              {member.work}
            </span>
            <span className=" group-hover:underline duration-700 text-md text-gray-400 font-semibold ">
              {member.phone}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

const members = [
  
  {
    img: founder,
    name: "Gajendra kandangwa",
    phone: "9852053334",
    work: "Founder",
  },
  {
    img: offcer,
    name: "Chandika Khatri",
    phone: "9767223831",
    work: "Office Manager",
  },
  {
    img: gal5,
    name: "Sandhya Kandangwa",
    phone: "9812452301",
    work: "Reception Handiling",
  },
];
