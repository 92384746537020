import React, { useState } from "react";

export default function Apply() {
  const [formData, setFormData] = useState({
    FullName: "",

    email: "",
    phoneNumber: "",
    dob: "",
    fatherName: "",
    motherName: "",
    grandFatherName: "",
    permanentAddress: "",
    currentAddress: "",
    cityToll: "",
    gender: "",
    frontPhoto: null,
    backPhoto: null,
    relationshipDocuments: null,
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailBody = `
      Full Name: ${formData.FullName}%0D%0A
    
      Email Address: ${formData.email}%0D%0A
      Phone Number: ${formData.phoneNumber}%0D%0A
      D.O.B: ${formData.dob}%0D%0A
      Father Name: ${formData.fatherName}%0D%0A
      Mother Name: ${formData.motherName}%0D%0A
      Grand Father Name: ${formData.grandFatherName}%0D%0A
      Permanent Address: ${formData.permanentAddress}%0D%0A
      Current Address: ${formData.currentAddress}%0D%0A
      City/Toll: ${formData.cityToll}%0D%0A
      Gender: ${formData.gender}%0D%0A
    `;

    window.open(
      `mailto:grikseducation@gmail.com?subject=Visa Application&body=${emailBody}`
    );
  };

  return (
    <div className="flex justify-center items-center">
      <div className="md:flex border md:px-12 px-4 py-6 md:py-10 justify-between w-full md:w-10/12 gap-5">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 md:flex-1">
          <div className="flex justify-between items-center">
            <h1 className="text-[20px] text-black/90">OUR VISA APPLY</h1>
          </div>
          <div className="border-b border-gray-300"></div>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="flex flex-col gap-1">
              <span className="text-[21px] text-primary-400">Full Name</span>
              <input
                className="px-2 outline-primary-150 w-full border border-gray-300 py-2 placeholder:text-gray-400"
                type="text"
                name="name"
                placeholder="full name"
                required
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col gap-1">
              <span className="text-[21px] text-primary-400">
                Email Address
              </span>
              <input
                className="px-2 outline-primary-150 w-full border border-gray-300 py-2 placeholder:text-gray-400"
                name="email"
                type="email"
                placeholder="examples@gmail.com"
                required
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[21px] text-primary-400">Phone Number</span>
              <input
                className="px-2 outline-primary-150 flex-1 w-full border border-gray-300 py-2 placeholder:text-gray-400"
                name="phoneNumber"
                type="number"
                placeholder="phone"
                required
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[21px] text-primary-400">D.O.B</span>
              <input
                className="px-2 outline-primary-150 w-full border border-gray-300 py-2 placeholder:text-gray-400"
                type="date"
                name="dob"
                placeholder="date of birth"
                required
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[21px] text-primary-400">Father Name</span>
              <input
                className="px-2 outline-primary-150 w-full border border-gray-300 py-2 placeholder:text-gray-400"
                type="text"
                name="fatherName"
                placeholder="father name"
                required
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[21px] text-primary-400">Mother Name</span>
              <input
                className="px-2 outline-primary-150 w-full border border-gray-300 py-2 placeholder:text-gray-400"
                type="text"
                name="motherName"
                placeholder="mother name"
                required
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[21px] text-primary-400">
                Grand Father Name
              </span>
              <input
                className="px-2 outline-primary-150 w-full border border-gray-300 py-2 placeholder:text-gray-400"
                type="text"
                name="grandFatherName"
                placeholder="grand father name"
                required
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[21px] text-primary-400">
                Permanent Address
              </span>
              <input
                className="px-2 outline-primary-150 w-full border border-gray-300 py-2 placeholder:text-gray-400"
                type="text"
                name="permanentAddress"
                placeholder="permanent address"
                required
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[21px] text-primary-400">
                Current Address
              </span>
              <input
                className="px-2 outline-primary-150 w-full border border-gray-300 py-2 placeholder:text-gray-400"
                type="text"
                name="currentAddress"
                placeholder="current address"
                required
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[21px] text-primary-400">City/Toll</span>
              <input
                className="px-2 outline-primary-150 w-full border border-gray-300 py-2 placeholder:text-gray-400"
                type="text"
                name="cityToll"
                placeholder="city/toll"
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <span className="text-xl">Gender</span>
            <span className="flex items-center justify-center gap-2 text-xl">
              <input
                className="h-4 w-5"
                type="checkbox"
                name="gender"
                value="Male"
                onChange={handleChange}
              />
              <span>Male</span>
            </span>
            <span className="flex items-center justify-center gap-2 text-xl">
              <input
                className="h-4 w-5"
                type="checkbox"
                name="gender"
                value="Female"
                onChange={handleChange}
              />
              <span>Female</span>
            </span>
            <span className="flex items-center justify-center gap-2 text-xl">
              <input
                className="h-4 w-5"
                type="checkbox"
                name="gender"
                value="Other"
                onChange={handleChange}
              />
              <span>Other</span>
            </span>
          </div>
          <div className="md:flex justify-between md:pt-7">
            <span className="text-xl">Passport Photo :-</span>
            <span className="md:flex justify-center items-center gap-2">
              <h2 className="md:mt-0 mt-3 text-xl">front</h2>
              <input
                required
                type="file"
                name="frontPhoto"
                className="md:mt-0 mt-2"
                onChange={handleChange}
              />
            </span>
            <span className="md:flex justify-center items-center gap-2">
              <h2 className="md:mt-0 mt-3 text-xl">back</h2>
              <input
                required
                type="file"
                name="backPhoto"
                className="md:mt-0 mt-2"
                onChange={handleChange}
              />
            </span>
          </div>
          <div className="md:flex md:gap-60 md:pt-7">
            <span className="text-xl">Relationship documents :-</span>
            <input
              required
              type="file"
              name="relationshipDocuments"
              className="md:mt-0 mt-2"
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            className="flex justify-center items-center mt-10 rounded-md bg-primary-450 py-3 text-white hover:bg-primary-500 duration-500 hover:duration-500"
          >
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
}
