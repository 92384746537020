import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { IoStarHalf } from "react-icons/io5";
import rajan from "../assets/rajan.jpg";
import abhi from "../assets/abhi.jpg";
import hero2 from "../assets/hero2.jpg"

export default function Testmonirals() {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const testimonials = [
    {
      avatar: rajan,
      name: " Rajan Bahadur Shahi",
      // title: "Founder of meta",
      quote:
        "Griks Global Education Consultancy is highly esteemed within the education industry, recognized for its exceptional services and expertise in guiding students towards their academic objectives.",
    },
    {
      avatar: abhi,
      name: "Abishek Mishra",
      // title: "Product designer",
      quote:
        "With a team of experienced consultants, Griks provides personalized assistance to students in various aspects of their educational journey, including admissions, test preparation, and visa applications.",
    },
    {
      avatar: hero2,
      name: "Manisha Sharma",
      // title: "DevOp engineer",
      quote:
        " Griks boasts a proven track record of helping students achieve their academic goals, evidenced by numerous testimonials from satisfied clients who have benefited from their guidance and support.",
    },
  ];

  return (
    <div className=" md:-mt-0 -mt-10  md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex  justify-between  md:w-10/12 w-full">
        <div className=" flex flex-col md:gap-7 gap-5 md:flex-1 md:border-r md:pr-0 pr-4">
          <span className="  md:text-4xl text-3xl text-gray-700 font-semibold">
            Hear Reviews From Our Amazing Students
          </span>
          <span className=" md:text-xl text-md text-gray-500">
            "Griks Global Education Consultancy garners rave reviews for its
            personalized support and transparent guidance. Clients applaud its
            success in admissions, test prep, and visa assistance, making Griks
            a trusted ally for students navigating their academic journey."
          </span>
          <div className="  flex flex-col gap-3 bg-primary-500 hover:bg-primary-550 cursor-pointer duration-500 md:w-max md:px-16 px-4 py-7 rounded-2xl">
            <span className=" text-gray-300 font-semibold text-2xl">
              Average Rating Now
            </span>
            <span className=" flex items-center gap-3">
              <p className=" text-gray-400 font-semibold  text-4xl">4.8</p>
              <span className="  text-primary-300 grid grid-cols-4 gap-2">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <IoStarHalf />
              </span>
            </span>
          </div>
        </div>
        <div className=" md:flex-1">
          <section className=" md:pt-0 pt-10">
            <div className="max-w-screen-xl mx-auto  md:px-8">
              <div className="max-w-3xl mx-auto text-center">
                <h3 className="  text-gray-700 font-semibold  pb-8 text-2xl md:text-3xl">
                  What people are saying
                </h3>
                <ul>
                  {testimonials.map((item, idx) =>
                    currentTestimonial === idx ? (
                      <li key={idx}>
                        <figure>
                          <blockquote>
                            <p className="text-gray-600   text-md sm:text-xl">
                              {item.quote}
                            </p>
                          </blockquote>
                          <div className="mt-8">
                            <img
                              alt=""
                              src={item.avatar}
                              className="w-16 h-16 mx-auto rounded-full object-cover cursor-pointer"
                            />
                            <div className="mt-4">
                              <span className="block text-gray-500 font-semibold italic">
                                {item.name}
                              </span>
                              {/* <span className="block text-gray-600 text-sm mt-0.5">
                                {item.title}
                              </span> */}
                            </div>
                          </div>
                        </figure>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </ul>
              </div>
              <div className="mt-6">
                <ul className="flex gap-x-3 justify-center">
                  {testimonials.map((item, idx) => (
                    <li key={idx}>
                      <button
                        className={`w-2.5 h-2.5 rounded-full duration-150 ring-offset-2 ring-indigo-600 focus:ring ${
                          currentTestimonial === idx
                            ? "bg-indigo-600"
                            : "bg-gray-300"
                        }`}
                        onClick={() => setCurrentTestimonial(idx)}
                      ></button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
