import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
// import TopNavbar from "./components/TopNavbar";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Videos from "./pages/Videos";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// import Services from "./pages/Services";
import Message from "./components/Message";
import Apply from "./pages/Apply";
import OurServices from "./pages/OurServices";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className=" overflow-y-hidden">
      {/* <TopNavbar /> */}

      <BrowserRouter>
        <Navbar />
        <div className=" md:py-9 py-4 mb-20">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/videos" element={<Videos />} />
            {/* <Route path="/services" element={<Services />} /> */}
            <Route path="/apply" element={<Apply />} />
            <Route path="/ourServices" element={<OurServices />} />

          </Routes>{" "}
        </div>
        <Message />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
