import React from "react";
import gal5 from "../assets/gal7.jpg";
import { items } from "../data/Trainers";

export default function About() {
  return (
    <div className="  overflow-y-hidden flex justify-center items-center">
      <div className="md:px-0 px-4 flex justify-center items-center md:w-10/12 flex-col md:gap-20 gap-12">
        <div className=" md:flex justify-between  gap-20">
          <div className=" flex flex-col gap-7">
            <span className=" md:mt-0 mt-5 md:text-3xl text-2xl text-gray-700 font-semibold tracking-widest">
              About The Griks Global Education
            </span>
            <span className=" flex flex-col gap-5">
              <h1 className=" text-gray-500">
                Your Gateway to Japan: Embark on a Global Educational Journey
                with Griks Global Education Consultancy. Welcome to Griks Global
                Education Consultancy, your trusted partner for navigating the
                vibrant educational landscape of Japan. With our deep expertise
                and unwavering commitment to excellence, we specialize in
                guiding students from around the world to achieve their academic
                aspirations with a 100% visa success rate. At Griks Global
                Education Consultancy, we understand the importance of seamless
                transitions and unparalleled support for students pursuing their
                educational dreams in Japan. Our dedicated team of experts
                offers personalized guidance tailored to your individual goals,
                whether you're interested in undergraduate, graduate, language,
                or cultural exchange programs. From assisting with university
                selection and application support to providing expert visa
                guidance and post-arrival assistance, we're here to ensure your
                journey to Japan is smooth and rewarding. With Griks Global
                Education Consultancy by your side, you can embark on your
                educational adventure with confidence, knowing that you have
                access to a world of opportunities and support backed by our
                impeccable track record of 100% visa success. Start your journey
                with us today and let's turn your educational dreams into
                reality, wherever they may take you."
              </h1>
              <h1 className=" text-gray-500">
                Griks Global Education Consultancy offers invaluable benefits to
                students with expert guidance, personalized support, and a
                proven track record of success. From admissions to visa
                applications, Griks ensures students receive comprehensive
                assistance, fostering trust and confidence in their academic
                journey.
              </h1>
            </span>
          </div>
          <img
            className=" md:mt-0 mt-10 object-cover rounded-xl h-60 md:h-96"
            src={gal5}
            alt=""
          />
        </div>
        <div className=" flex flex-col gap-5 justify-center items-center">
          <div className=" pb-5 flex flex-col gap-1 justify-center items-center text-center relative max-w-xl mx-auto sm:text-center">
            <div className=" tracking-widest text-gray-700 text-2xl  sm:text-3xl">
              <span className=" text-primary-350">Our</span> Members
            </div>
            <div className=" flex flex-col gap-1">
              <span className=" border-b-2 border-primary-350  w-32"></span>
              <span className=" ml-8 border-b-2 border-gray-700  w-32"></span>
            </div>
          </div>
          <div className="  pb-10  justify-center items-center grid lg:grid-cols-2  md:gap-20 gap-4">
            {items.map((item, index) => (
              <div
                key={index}
                className=" md:w-max  justify-center items-center shadow-2xl hover:scale-105 duration-700  rounded-xl flex flex-col gap-3 px-4 py-7"
              >
                <img
                  className=" flex rounded-xl   md:h-72 md:w-72 h-96  w-full object-cover"
                  src={item.img}
                  alt=""
                />
                <div className=" flex md:justify-center md:items-center flex-col gap-2">
                  <span className="  italic text-2xl text-primary-350 hover:text-primary-250 duration-300 cursor-pointer">
                    {item.name}
                  </span>
                  <span className=" text-xl text-gray-600 font-semibold">
                    {item.work}
                  </span>
                  <span className=" md:w-96 w-full text-sm text-gray-500">
                    {item.desc}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
