import React from "react";

export default function OurServices() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col gap-8 items-center justify-center md:w-10/12">
        <div className=" flex flex-col gap-2 md:items-center">
          <p className="  md:text-4xl text-xl text-gray-700 font-semibold cursor-pointer">
            Griks Global Education Consultancy{" "}
          </p>
          <p className="  md:text-[17px] text-gray-500">
            Japan ( Can move to Osaka, Tokyo, Nagoya, Ibaraki generally most
            part of Japan)
          </p>
        </div>
        <div className=" flex flex-col gap-2 md:items-center">
          <p className="  md:text-3xl text-xl text-gray-700 font-semibold">
            Visa of Japanese language{" "}
          </p>
          <p className=" text-primary-300 text-xl cursor-pointer hover:text-primary-350 duration-500">
            (1) STUDENT VISA{" "}
          </p>
          <p className=" text-primary-300 text-xl cursor-pointer hover:text-primary-350 duration-500">
            (2) WORKING VISA
          </p>
        </div>
        <div className=" flex flex-col gap-4 justify-center items-center">
          <div className=" flex justify-center items-center bg-primary-450 md:px-24 md:w-max  w-full py-2 md:text-xl  text-gray-100 hover:bg-primary-400 cursor-pointer duration-500 ">
            <button>Required Documents for Student Visa</button>
          </div>
          <div className=" flex flex-col gap-1 ">
            <p className=" text-xl cursor-pointer">1 Passport </p>
            <p className=" text-xl cursor-pointer">2 MRP size photo (12 pcs)</p>
            <p className=" text-xl cursor-pointer">
              3 Sponsor business income (annual 40 lakhs)
            </p>
            <p className=" text-xl cursor-pointer">
              4 Tax clearance documents{" "}
            </p>
            <p className=" text-xl cursor-pointer">5 Relationship documents </p>
            <p className=" text-xl cursor-pointer">
              {" "}
              6 Bank translation (service provided by us)
            </p>
          </div>{" "}
        </div>
        {/* <div className=" flex justify-center items-center bg-primary-450 md:px-24 w-full py-2 md:text-xl  text-gray-100 hover:bg-primary-400 cursor-pointer duration-500 ">
          <button>Cost To Process RS.13,00,000 </button>
        </div>
        <div className=" flex justify-between w-full flex-col gap-4 border ">
          <div className=" flex justify-between border-b">
            <p className="  px-2  py-2 md:text-xl">Cost for:</p>
            <p className="  px-2  py-2 md:text-xl">Amount in Nrs </p>
            <p className="  px-2  py-2 md:text-xl">Date of payment</p>
          </div>
          <div className=" flex  justify-between border-b">
            <p className="  px-2  py-2 md:text-xl">Admission </p>
            <p className="  px-2  py-2 md:text-xl">5000 </p>
            <p className="  px-2  py-2 md:text-xl">Before joining the class</p>
          </div>
          <div className=" flex  justify-between border-b">
            <p className="  px-2  py-2 md:text-xl">Tuition fees </p>
            <p className="  px-2  py-2 md:text-xl">10,000 per month </p>
            <p className="  px-2  py-2 md:text-xl">Before joining the class </p>
          </div>
          <div className=" flex  justify-between border-b">
            <p className="  px-2  py-2 md:text-xl">Visa application fees </p>
            <p className="  px-2  py-2 md:text-xl">1,00,000 </p>
            <p className="  px-2  py-2 md:text-xl">
              After passing the interview{" "}
            </p>
          </div>
          <div className=" flex justify-between border-b">
            <p className="  px-2  py-2 md:text-xl">Other amount</p>
            <p className="  px-2  py-2 md:text-xl">Net amount  </p>
            <p className="  px-2  py-2 md:text-xl">After receipt of COE.</p>
          </div>
        </div>
        <div></div> */}
      </div>
    </div>
  );
}
