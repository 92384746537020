
import founder from "../assets/gajendra.jpg"
import offcer from "../assets/chand.jpg"



export const items = [
  {
    img: founder,
    name: "Gajendra kandangwa",
    work: "Founder",
    desc: "We're here to make your journey seamless and successful. With my leadership as Chairman, you can expect personalized support and expert guidance every step of the way. We understand the importance of personalized guidance and expert support in navigating the complexities of the education landscape. Trust us to be your partner in success.",
  },
  {
    img: offcer,
    name: "Chandika Khatri",
    work: "Office Manager",
    desc: "As the Office Manager, I'm here to ensure your experience with us is smooth and efficient. From handling inquiries to coordinating services, our team is dedicated to provide efficient and reliable support throughout your educational journey. Your satisfaction is our priority, and we look forward to assisting you every step of the way.",
  },
  
];