import React from "react";

export default function Card() {
  return (
    <div className=" flex flex-col gap-10 justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-6">
        <span className=" md:text-4xl text-3xl text-gray-700 font-semibold tracking-widest">
          {" "}
          Why You Choose Us ?
        </span>
        <span className=" md:px-64 px-4 md:text-xl  text-gray-500 ">
        Griks Global Education Consultancy is widely regarded as a premier education center, renowned for its tailored services and expert guidance. With a team of highly qualified consultants, Griks offers personalized support in admissions, test preparation, and visa applications. Their track record of success and commitment to transparency make them a trusted choice for students seeking to achieve their goals.
        </span>
      </div>
      <div className=" grid md:grid-cols-3 justify-center items-center  md:px-24  gap-10">
        <div className=" md:w-[400px] w-[350px] h-[200px] card">
          <div className="card-inner">
            <div className=" md:px-7 px-5 flex flex-col gap-1 card-front">
              <p className=" text-gray-300 font-semibold ">Modern Methodology</p>
              <p className=" text-[16px] text-gray-400 font-semibold">
              Griks Global Education Consultancy utilizes modern methodologies to optimize student success, ensuring efficient and effective support throughout the academic journey.
              </p>
            </div>
            <div className=" md:px-7 px-2 card-back  text-gray-300">
              <p>Welcome To Nepal Griks Global Education Consultancy Pvt.Ltd</p>
            </div>
          </div>{" "}
        </div>

        <div className=" md:w-[400px] w-[350px] h-[200px] card">
          <div className="card-inner">
            <div className="  md:px-7 px-5 flex flex-col gap-1 card-front">
              <p className=" text-gray-300 font-semibold ">100% Success Visa Apply</p>
              <p className=" text-[16px] text-gray-400  font-semibold" >
              Griks Global Education Consultancy boasts a remarkable 100% success rate in visa applications, reflecting its unparalleled expertise and dedication to ensuring students.
              </p>
            </div>
            <div className=" md:px-7 px-2 card-back  text-gray-300">
              <p>Welcome To Nepal Griks Global Education Consultancy Pvt.Ltd</p>
            </div>
          </div>{" "}
        </div>

        <div className=" md:w-[400px] w-[350px] h-[200px] card">
          <div className="card-inner">
            <div className="  md:px-7 px-5  flex flex-col gap-1 card-front">
              <p className=" text-gray-300 font-semibold ">Growth Analysis</p>
              <p className=" text-[16px] text-gray-400 font-semibold">
              Griks Global Education Consultancy conducts comprehensive growth analysis, providing insights to optimize students' educational journeys and achieve their academic goals.
              </p>
            </div>
            <div className=" md:px-7 px-2 card-back text-gray-300">
              <p>Welcome To Nepal Griks Global Education Consultancy Pvt.Ltd</p>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
