import React, { useEffect } from "react";
import { useState } from "react";
import logo from "../assets/logo1.png";
import { IoIosCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import Headroom from "react-headroom";

const Navbar = () => {
  const [state, setState] = useState(false);

  const location = useLocation();
  console.log(location.pathname);

  const navigation = [
    { title: "Home", path: "/" },
    { title: "Abouts Us", path: "/about" },
    { title: "Gallery", path: "/gallery" },
    { title: "Videos", path: "/videos" },
    { title: "Our Services", path: "/ourServices" },
    { title: " Visa Apply", path: "/apply" },
    { title: "Contact Us", path: "/contact" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setState(false); // Close the mobile menu on navigation
  }, [location.pathname]);

  return (
    <Headroom className=" z-50">
      <nav className="    bg-gray-100   border-gray-300 border-b w-full md:static md:text-sm ">
        <div className="items-center   md:w-10/12 w-full md:px-0 px-4 mx-auto md:flex ">
          <div className="flex items-center justify-between py-3 md:py-4 md:block">
            <Link to="/" className=" flex  items-center gap-2  ">
              <div>
                <img
                  src={logo}
                  className=" h-12 object-cover cursor-pointer"
                  alt=" logo"
                />
              </div>
              <p className=" text-primary-450 flex justify-center items-center text-[18px] font-serif font-semibold">
                Griks Global
              </p>
            </Link>

            <div className="md:hidden">
              <button
                className=" text-primary-500  hover:text-primary-350"
                onClick={() => setState(!state)}
              >
                {state ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-primary-500  hover:text-primary-350"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div
            className={`flex-1 pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              state ? "block" : "hidden"
            }`}
          >
            <ul className="justify-end items-center space-y-6 md:flex md:space-x-9 md:space-y-0">
              {navigation.map((item, idx) => {
                return (
                  <li
                    key={idx}
                    className={`${
                      location.pathname === item.path
                        ? " text-primary-400 underline font-semibold "
                        : ""
                    }     text-gray-700 md:font-semibold    font-serif  md:text-[19px] text-[18px] hover:text-primary-400    duration-500 hover:underline font-semibold`}
                  >
                    <Link to={item.path} className="block">
                      {item.title}
                    </Link>
                  </li>
                );
              })}

              <div className=" border-t border-gray-400  md:hidden  grid  flex-1 gap-5">
                <div className=" mt-2 text-[18px] flex-1  text-primary-500 cursor-pointer">
                  {" "}
                  Griks Global Education Consultacncy Pvt.Ltd
                </div>
                <div className="  cursor-pointer flex items-center  text-primary-450 gap-4">
                  <span className=" animate-bounce">
                    <IoIosCall size={20} />
                  </span>
                  <span className=" text-gray-600"> +977 9852053334</span>
                </div>
                <div className=" cursor-pointer flex items-center text-primary-450 gap-4">
                  <span className=" animate-bounce duration-1000">
                    <MdEmail size={20} />
                  </span>
                  <span className=" text-gray-600">
                    infogriksglobal@gmail.com
                  </span>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </Headroom>
  );
};

export default Navbar;
