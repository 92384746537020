import React from "react";
import Testmonirals from "../components/Testmonirals";

import Herosection from "../components/Herosection";

import Teams from "../components/Teams";
import Card from "../components/Card";
import ImageSlider from "../components/ImageSlider";

export default function Home() {
  return (
    <div className=" flex flex-col md:gap-36 gap-20">
      <Herosection />
      <Testmonirals />
      <Card />
      <ImageSlider />
      <Teams />
    </div>
  );
}
