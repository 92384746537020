import React from "react";
import contact from "../assets/contact.png";
import { MdLocationPin } from "react-icons/md";
import { CgMail } from "react-icons/cg";
import { IoCallSharp } from "react-icons/io5";

export default function Contact() {
  return (
    <div className=" flex flex-col justify-center items-center md:gap-16 gap-5 md:px-0 px-4">
      <span
        className="   md:text-4xl text-3xl text-gray-700 font-semibold
        "
      >
        Contact Griks Global Education Consultacncy Pvt.Ltd
      </span>
      <main className="">
        <div className=" md:flex  justify-between items-center  md:w-10/12 mx-auto  text-gray-600 ">
          <div className=" flex md:justify-start justify-center items-center md:pr-36">
            <img
              className="  h-72 object-cover cursor-pointer "
              src={contact}
              alt=""
            />
          </div>
          <div className=" md:mt-0 mt-8  md:flex-1 gap-12 justify-between lg:flex ">
            <div className="space-y-7">
              <h3 className="text-gray-700  text-2xl sm:text-3xl">
                Griks Global SUPPORT & SERVICE
              </h3>
              <p className="text-gray-600  text-xl sm:text-2xl">
                Always at your service
              </p>
              <p className=" text-gray-500  text-[17px]">
                We’re here to help and answer any question you might have, We
                look forward to hearing from you! Please fill out the form, or
                us the contact information bellow .
              </p>
              <div className=" md:flex  gap-20">
                <div className=" flex flex-col gap-2">
                  <span className=" md:mt-0 mt-10 text-2xl  font-semibold text-gray-700">
                    {" "}
                    For Service Inquiries
                  </span>
                  <span>grikseducation@gmail.com</span>
                  <span>
                    <span className=" pr-2 text-xl  font-semibold text-gray-600">
                      Phone No:
                    </span>
                    01-5920665
                  </span>
                </div>
                <div className=" md:mt-0 mt-10  flex flex-col gap-2">
                  <span className=" text-2xl  font-semibold text-gray-700">
                    {" "}
                    For 24/7 Support
                  </span>
                  <span>infogriksglobal@gmail.com</span>
                  <span>
                    <span className="text-xl pr-2  font-semibold text-gray-600">
                      WhatsApp:
                    </span>
                    +977 9852053334
                  </span>
                  <span>
                    <span className=" pr-2 text-[17px] font-semibold text-gray-600">
                      Cell:
                    </span>
                    9852053334,  9767223831
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="  justify-between items-center md:w-10/12 md:flex  ">
        <div className=" md:mt-0 mt-8  flex flex-col gap-2">
          <div className=" md:flex gap-1 justify-center items-center">
            <span>
              <MdLocationPin
                size={30}
                className=" cursor-pointer object-cover text-primary-350 animate-bounce duration-500"
              />
            </span>
            <span className=" text-2xl  font-semibold text-gray-700">
              Visit us
            </span>
          </div>
          <div>
          Griks Global Education Consultacncy Pvt.Ltd Kathmandu 10 New Baneshower, Nepal
          </div>
        </div>
        <div className=" flex flex-col gap-2">
          <div className=" md:mt-0 mt-6  md:flex gap-1 justify-center items-center">
            <span>
              <CgMail
                size={30}
                className=" cursor-pointer object-cover text-primary-350 animate-bounce duration-500"
              />
            </span>
            <span className=" text-2xl  font-semibold text-gray-700">
              Email us
            </span>
          </div>
          <div>infogriksglobal@gmail.com</div>
        </div>
        <div className="  md:mt-0 mt-6 flex flex-col gap-2">
          <div className=" md:flex gap-1 justify-center items-center">
            <span>
              <IoCallSharp
                size={30}
                className=" cursor-pointer object-cover text-primary-350 animate-bounce duration-500"
              />
            </span>
            <span className=" text-2xl  font-semibold text-gray-700">
              Call us
            </span>
          </div>
          <div> +977 9852053334</div>
        </div>
      </div>
      <div className=" mt-10 flex justify-center items-center">
        <iframe
          title="Unique Title"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4201.213150860155!2d85.33481040681218!3d27.69303678880438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19004ce2ba79%3A0xbf5cc4dbccacc1dc!2sGriks%20Global%20Education%20Consultancy%20Pvt.%20Ltd!5e0!3m2!1sne!2snp!4v1715068386891!5m2!1sne!2snp"
          referrerpolicy="no-referrer-when-downgrade"
          className=" md:w-[1300px] md:h-[450px] h-96 w-full"
        ></iframe>
      </div>
    </div>
  );
}
