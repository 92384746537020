import React from "react";
import glo1 from "../assets/glo1.jpg";
import glo2 from "../assets/glo2.jpg";
import glo3 from "../assets/glo3.jpg";
import glo4 from "../assets/glo4.jpg";
import glo5 from "../assets/glo5.jpg";
import glo6 from "../assets/glo6.jpg";
import glo7 from "../assets/glo7.jpg";
import glo8 from "../assets/glo8.jpg";
import glo10 from "../assets/glo10.jpg";
import glo11 from "../assets/glo11.jpg";
import grik1 from "../assets/grik1.jpg";
import grik2 from "../assets/grik2.jpg";
import gal1 from "../assets/gal1.jpg";
import gal3 from "../assets/gal3.jpg";
import gal4 from "../assets/gal4.jpg";
import gal8 from "../assets/gal8.jpg";

import gn1 from "../assets/gn1.jpg";
import gn2 from "../assets/gn2.jpg";
import gn3 from "../assets/gn3.jpg";
import gn4 from "../assets/gn4.jpg";
import gn5 from "../assets/gn5.jpg";
import gn6 from "../assets/gn6.jpg";
import gn7 from "../assets/gn7.jpg";
import gn8 from "../assets/gn8.jpg";

export default function Gallery() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 justify-center items-center md:w-10/12">
        {images.map((image, index) => (
          <div key={index}>
            <img
              data-aos="flip-left"
              data-aos-duration="1500"
              className=" h-96 w-96 object-cover cursor-pointer rounded-md"
              src={image.img}
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const images = [
  {
    img: gn1,
  },
  {
    img: gn2,
  },
  {
    img: gn3,
  },
  {
    img: gn4,
  },
  {
    img: gn5,
  },
  {
    img: gn6,
  },
  {
    img: gn7,
  },
  {
    img: gn8,
  },
  {
    img: gal1,
  },
  {
    img: glo1,
  },
  {
    img: glo2,
  },

  {
    img: glo3,
  },
  {
    img: glo4,
  },
  {
    img: glo5,
  },
  {
    img: glo6,
  },
  {
    img: glo7,
  },
  {
    img: glo8,
  },
  {
    img: glo10,
  },
  {
    img: glo11,
  },
  {
    img: grik1,
  },
  {
    img: gal3,
  },
  {
    img: gal4,
  },

  {
    img: gal8,
  },

  {
    img: grik2,
  },
];
