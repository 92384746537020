import React from "react";
// import hero1 from "../assets/hero1.jpg";
import glo6 from "../assets/glo8.jpg";
import glo4 from "../assets/glo11.jpg";
import glo1 from "../assets/glo1.jpg";
import glo7 from "../assets/glo3.jpg";

// import { LiaStarSolid } from "react-icons/lia";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

const Herosection = () => {
  return (
    <div className=" z-0 -mt-16">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {sliderImages.map((sliderImage, index) => (
          <SwiperSlide>
            <div key={index} className="  md:py-0 py-2 ">
              <div className="  relative md:h-[100vh] md:w-[100vw] bg-gradient-to-br   from-primary-300    to-primary-450 h-[50vh] w-full flex justify-center items-center flex-col   ">
                <img
                  className="  absolute    w-full h-full mix-blend-overlay  object-cover"
                  src={sliderImage.img}
                  alt=""
                />
                <div className=" md:px-0 px-4 z-30 absolute md:w-10/12  flex   justify-center items-center flex-col gap-5">
                  <div className=" flex items-center gap-4">
                    {/* <span className=" text-primary-500">
                      {sliderImage.icon}
                    </span> */}
                    <span className="   md:text-5xl text-2xl text-gray-100  tracking-widest ">
                      {sliderImage.type}
                    </span>{" "}
                  </div>
                  <span className=" tracking-widest  md:text-2xl text-xl    text-gray-200  font-lightflex justify-center items-center flex-col gap-1">
                    {sliderImage.desc}
                  </span>
                  <Link
                    to={sliderImage.path}
                    className=" flex  hover:bg-gray-200  duration-500 hover:scale-105  bg-gray-100   text-gray-600 font-semibold rounded-xl md:px-10 md:text-xl md:py-2 py-1 px-9 w-max"
                  >
                    {sliderImage.btn}
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Herosection;

const sliderImages = [
  {
    // icon: <LiaStarSolid size={22} />,
    type: " Griks Global Education Consultancy Pvt.Ltd ",
    img: glo1,
    desc: " Emphasis on transparency and building trust with clear communication.",
    btn: "More About",
    path: "/about",
  },
  {
    // icon: <LiaStarSolid size={22} />,
    type: " Griks Global Education Consultancy Pvt.Ltd ",
    img: glo7,
    desc: " The Best Education Consultancy in Nepal.",
    btn: "More About",
    path: "/about",
  },

  {
    // icon: <LiaStarSolid size={22} />,
    img: glo6,
    type: "Griks Global Education Consultancy Pvt.Ltd ",
    desc: " Proven success record in admissions, test prep, and visa applications.",
    btn: "More About",
    path: "/about",
  },
  {
    // icon: <LiaStarSolid size={22} />,
    type: "Griks Global Education Consultancy Pvt.Ltd ",
    img: glo4,
    desc: " Comprehensive services covering all aspects of studying abroad.",
    btn: "More About",
    path: "/about",
  },

  // {
  //   icon: <LiaStarSolid size={22} />,
  //   type: "Nepal Griks Global Education Consultacncy Pvt.Ltd ",
  //   img: oldman1,
  //   desc: "सुस्त मनष्धित व्यक्तित्वहरूको मनोवल बढाउन उत्प्रेरणा गर्ने ",
  //   btn: "Contact Us",
];
