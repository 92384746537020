import React from "react";
import h1 from "../assets/h1.jpg";
import h2 from "../assets/h2.jpg";
// import h3 from "../assets/h3.jpg";
import h4 from "../assets/h4.jpg";
import h5 from "../assets/h5.jpg";
import h6 from "../assets/h6.jpg";
import h7 from "../assets/h7.jpg";
import h8 from "../assets/h8.jpg";
import h9 from "../assets/h9.jpg";
import h10 from "../assets/h10.jpg";
import h11 from "../assets/h11.jpg";
import h12 from "../assets/h12.jpg";
import h13 from "../assets/h13.jpg"
import h18 from "../assets/h18.jpg";
import h19 from "../assets/h19.jpg";
import h20 from "../assets/h20.jpg";
import h21 from "../assets/h21.jpg";


import Marquee from "react-fast-marquee";

export default function ImageSlider() {
  return (
    <div className="  -z-20 flex justify-center items-center">
      <div className=" w-full">
        <Marquee speed={20} className="mt-12">
          <div className="grid sm:grid-cols-2 md:grid-cols-4">
            {team.map((item, idx) => (
              <div key={idx}>
                <div className="  w-full h-60 sm:h-52 md:h-72">
                  <img
                    src={item.image}
                    className=" hover:opacity-70 hover:scale-95 duration-700 w-full h-full object-cover object-center"
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
        </Marquee>
        <Marquee direction="right" speed={20}>
          <div className="grid sm:grid-cols-2 md:grid-cols-4">
            {teams.map((item, idx) => (
              <div key={idx}>
                <div className="  w-full h-60 sm:h-52 md:h-72">
                  <img
                    src={item.image}
                    className=" hover:opacity-70 hover:scale-95 duration-700 w-full h-full object-cover object-center"
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
        </Marquee>
      </div>
    </div>
  );
}

const team = [
  {
    image: h20,
  },
  {
    image: h21,
  },
  {
    image: h19,
  },
  {
    image: h4,
  },
  {
    image: h5,
  },
  {
    image: h6,
  },
  {
    image: h7,
  },
  {
    image: h8,
  },
];

const teams = [
  {
    image: h9,
  },
  {
    image: h10,
  },
  {
    image: h11,
  },
  {
    image: h12,
  },
  {
    image: h13,
  },
  {
    image: h1,
  },

  {
    image: h2,
  },
  {
    image: h18,
  },
];
